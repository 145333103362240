// src/components/Filter/Filter.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Drawer,
    IconButton,
    Box,
    Typography,
    Slider,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { FaTemperatureThreeQuarters } from 'react-icons/fa6';
import DateSort from '../DateSort/DateSort'; // Adjust the path if necessary

const Filter = ({
    open,
    onClose,
    tempRange,
    handleTempChange,
    sortOrder,
    setSortOrder,
    resetFilter,
    styleFilter,
    setStyleFilter,
}) => {
    const { t } = useTranslation();

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box sx={{ width: 300, padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {t('filter')}
                </Typography>

                {/* Sort by Date */}
                <Box sx={{ my: 4 }}>
                    <DateSort sortOrder={sortOrder} setSortOrder={setSortOrder} />
                </Box>

                {/* Filter by Style */}
                <Box sx={{ mt: 4 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('style')}
                    </Typography>
                    <RadioGroup
                        value={styleFilter}
                        onChange={(e) => setStyleFilter(e.target.value)}
                    >
                        <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label={t('all')}
                        />
                        <FormControlLabel
                            value="classic"
                            control={<Radio />}
                            label={t('classic')}
                        />
                        <FormControlLabel
                            value="skate"
                            control={<Radio />}
                            label={t('skate')}
                        />
                    </RadioGroup>
                </Box>

                {/* Filter by Temperature */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, mt:4 }}>
                    <FaTemperatureThreeQuarters size={24} style={{ marginRight: 8 }} />
                    <Typography variant="subtitle1">{t('temp_range')}</Typography>
                </Box>
                <Slider
                    value={tempRange}
                    onChange={handleTempChange}
                    valueLabelDisplay="on"
                    sx={{ color: 'var(--color-btn)' }}
                    min={-35}
                    max={20}
                />

                {/* Bottom Buttons */}
                <Box sx={{ mt: 4, textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        sx={{
                            backgroundColor: 'var(--color-sbtn)',
                            color: 'var(--color-text)',
                            padding: '0.6rem 1rem',
                            marginRight: '0.5rem'
                        }}
                        onClick={resetFilter}
                    >
                        {t('reset')}
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: 'var(--color-sbtn)',
                            color: 'var(--color-text)',
                            padding: '0.6rem 1rem'
                        }}
                        onClick={onClose}
                    >
                        {t('close')}
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};

export default Filter;

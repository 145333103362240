// components/TestSummaryPage.jsx
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { addTestResult, updateTestArray } from '../../../firebase/firestoreFunctions';
import { mapRankingsToTournamentData } from '../../../helpers/helpers';
import ResultList from './ResultList/ResultList';
import Spinner from '../../../components/common/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import SaveTestInput from './SaveTestInput/SaveTestInput';
import { TournamentContext } from '../../../context/TournamentContext';
import { RiDeleteBinLine } from "react-icons/ri";


const TestSummaryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    selectedSkis,
    calculateRankings,
    resetTournament,
  } = useContext(TournamentContext);

  const [loading, setLoading] = useState(false);
  const [locationError, setLocationError] = useState(false);

  // If no skis are selected, redirect
  useEffect(() => {
    if (!selectedSkis || selectedSkis.length === 0) {
      navigate('/skis');
    }
  }, [selectedSkis, navigate]);

  const rankings = calculateRankings();

  // Build initial style from selected skis
  const determineInitialStyle = () => {
    const styles = [...new Set(selectedSkis.map((ski) => ski.style))];
    if (styles.length === 1) {
      return styles[0];
    } else if (styles.length > 1) {
      return 'classic';
    } else {
      return '';
    }
  };

  // Note: snowCondition is now an object
  const [additionalData, setAdditionalData] = useState({
    location: '',
    style: determineInitialStyle(),
    temperature: '',
    humidity: '',
    snowTemperature: '',
    comment: '',

    // Updated structure
    snowCondition: {
      source: '',     // "natural" or "artificial"
      grainType: '',  // "fresh", "fine_grained", etc.
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If name is either 'source' or 'grainType', it belongs in snowCondition
    if (name === 'source' || name === 'grainType') {
      setAdditionalData((prev) => ({
        ...prev,
        snowCondition: {
          ...prev.snowCondition,
          [name]: value,
        },
      }));
    } else {
      // Otherwise, set it on the top-level
      setAdditionalData({ ...additionalData, [name]: value });
    }
  };

  const handleSaveResults = async (e) => {
    e.preventDefault();
    if (!user) {
      console.log('User not found');
      return;
    }

    try {
      setLoading(true);

      // Pass the new structure in so Firestore document contains snowCondition: { source, grainType }
      const testId = await addTestResult(
        user.uid,
        mapRankingsToTournamentData(rankings, selectedSkis),
        additionalData
      );

      await updateAllTestArrays(user.uid, rankings, testId);

      resetTournament();
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      setLoading(false);
      navigate('/results');
    }
  };

  const styleOptions = [
    { label: t('classic'), value: 'classic' },
    { label: t('skate'), value: 'skate' },
  ];

  // Instead of a <select>, we now want radio inputs for source
  const snowSourceOptions = [
    { label: t('natural'), value: 'natural' },
    { label: t('artificial'), value: 'artificial' },
    { label: t('mix'), value: 'mix' },
  ];

  // No changes to the actual grainType list
  const snowGrainOptions = [
    { label: t('fresh'), value: 'fresh' },
    { label: t('fine_grained'), value: 'fine_grained' },
    { label: t('coarse_grained'), value: 'coarse_grained' },
    { label: t('wet'), value: 'wet' },
    { label: t('icy_conditions'), value: 'icy_conditions' },
    { label: t('sugary_snow'), value: 'sugary_snow' },
  ];

  // Automatically fetch and set location + weather
  useEffect(() => {
    const getPosition = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    };

    const fetchWeather = async (latitude, longitude) => {
      const apiKey = process.env.REACT_APP_OPENWEATHER_API_KEY;
      if (!apiKey) return;
      const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${apiKey}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        setAdditionalData((prevData) => ({
          ...prevData,
          location: data.name || '',
          temperature: data.main.temp ? Math.round(data.main.temp).toString() : '',
        }));
      } catch (error) {
        console.error('Could not fetch weather data', error);
      }
    };

    const requestLocation = () => {
      setLocationError(false);
      getPosition()
        .then((position) => {
          fetchWeather(position.coords.latitude, position.coords.longitude);
        })
        .catch((err) => {
          console.error("Could not get the user's position", err);
          setLocationError(true);
        });
    };

    requestLocation();
  }, []);

  const handleResetTest = () => {
    const confirmReset = window.confirm(t('reset_test_prompt'));
    if (confirmReset) {
      resetTournament();
    }
  };

  if (loading) return <Spinner />;

  return (
    <div className="py-4 px-2">
      <div className="space-y-5">
        <div>
          <ResultList rankings={rankings} />
        </div>
        <div>
          <form
            className="rounded flex flex-col text-black my-2"
            onSubmit={handleSaveResults}
          >
            <SaveTestInput
              type="text"
              name="location"
              placeholder={t('location')}
              onChange={handleInputChange}
              value={additionalData.location}
              required
            />

            <SaveTestInput
              type="select"
              name="style"
              placeholder={t('style')}
              onChange={handleInputChange}
              value={additionalData.style}
              required
              options={styleOptions}
            />

            <SaveTestInput
              type="number"
              name="temperature"
              placeholder={t('temperature')}
              value={additionalData.temperature}
              onChange={handleInputChange}
              required
            />

            {/**
             *  Radio buttons for the snow source.
             *  We ensure "required" by letting HTML handle it, or we can handle it ourselves.
             *  If you want to enforce “no default selection,”
             *  keep `additionalData.snowCondition.source = ""` as the initial state.
             */}
            <SaveTestInput
              type="radio"
              name="source"
              placeholder={t('snow_source')}
              value={additionalData.snowCondition.source}
              onChange={handleInputChange}
              options={snowSourceOptions}
              required
            />

            <SaveTestInput
              type="select"
              name="grainType"
              placeholder={t('snow_type')}
              value={additionalData.snowCondition.grainType}
              onChange={handleInputChange}
              options={snowGrainOptions}
              required
            />

            <SaveTestInput
              type="number"
              name="snowTemperature"
              placeholder={t('snow_temperature')}
              value={additionalData.snowTemperature}
              onChange={handleInputChange}
            />

            <SaveTestInput
              type="number"
              name="humidity"
              placeholder={t('humidity')}
              value={additionalData.humidity}
              onChange={handleInputChange}
            />

            <div className="col-span-2">
              <SaveTestInput
                type="text"
                name="comment"
                placeholder={t('comment')}
                value={additionalData.comment}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex col-span-3 items-center justify-between w-full">
              <div className='space-x-2'>
                <button
                  type="button"
                  className="px-5 py-3 bg-sbtn text-text rounded w-fit hover:bg-hoverSbtn"
                  onClick={() => navigate('/testing')}
                >
                  {t('back')}
                </button>
                <button
                  className="px-5 py-3 bg-btn text-btntxt rounded w-fit hover:opacity-90"
                  type="submit"
                >
                  {t('save')}
                </button>
              </div>
              <button
                type="button"
                className="p-4 h-fit bg-container text-delete rounded-full shadow w-fit"
                onClick={handleResetTest}
              >
                {<RiDeleteBinLine />}
              </button>
            </div>
          </form>
          {locationError && (
            <div className="text-red-500">
              {t('enable_location_services')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Update the function to update test arrays as usual
const updateAllTestArrays = async (userId, rankings, testId) => {
  for (const ranking of rankings) {
    await updateTestArray(userId, ranking.skiId, testId);
  }
};

export default TestSummaryPage;

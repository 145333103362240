import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';
import HomePage from '../pages/HomePage/HomePage';
import SkisPage from '../pages/SkisPage/SkisPage';
import AccountPage from '../pages/AccountPage/AccountPage';
import ResultsPage from '../pages/ResultsPage/ResultsPage';
import NotFoundPage from '../pages/NotFoundPage';
import SignIn from '../pages/auth/SignIn';
import SignUp from '../pages/auth/SignUp';
import ResetPassword from '../pages/auth/ResetPassword';
import TestingPage from '../pages/TestingPage/TestingPage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import AddSkisPage from '../pages/AddSkisPage/AddSkisPage';
import EditSkisPage from '../pages/EditSkisPage/EditSkisPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import EditResultPage from '../pages/ResultsPage/EditResult/EditResult';
import TestSummaryPage from '../pages/TestingPage/TestResultPage/TestSummaryPage';
import ManageLockedSkisPage from '../pages/ManageLockedSkisPage/ManageLockedSkisPage';
const AppRoutes = () => (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route element={<ProtectedRoute redirectTo="/signin" />}>
        <Route path="/skis" element={<SkisPage />} />
        <Route path="/manage-locked-skis" element={<ManageLockedSkisPage />} />
        <Route path="/add-skis" element={<AddSkisPage />} />
        <Route path="/edit-skis/:id" element={<EditSkisPage />} />
        <Route path="/testing" element={<TestingPage />} />
        <Route path="/testing/summary" element={<TestSummaryPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/edit-result/:resultId" element={<EditResultPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
);

export default AppRoutes;

// pages/AddSkisPage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSkis } from '../../hooks/useSkis';
import SkiForm from '../../components/SkiForm/SkiForm';
import Spinner from '../../components/common/Spinner/Spinner';
import { Helmet } from 'react-helmet-async';

const AddSkisPage = () => {
    const { addSki, loading, error } = useSkis();
    const navigate = useNavigate();

    const handleAddSki = async (formData) => {
        await addSki(formData);
        navigate('/skis');
    };

    if (loading) return <Spinner />;
    if (error) return <div className='m-2'>Error: {error.message}</div>;

    return (
        <div className="py-4 px-2">
            <Helmet>
                <title>Ski-Lab: Add skis</title>
                <meta name="description" content="Adding skis to your ski database" />

            </Helmet>
            <SkiForm onSubmit={handleAddSki} />
        </div>
    );
};

export default AddSkisPage;

// pages/EditSkisPage.jsx
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSingleSki } from '../../hooks/useSingleSki';
import { useSkis } from '../../hooks/useSkis';
import SkiForm from '../../components/SkiForm/SkiForm';
import Spinner from '../../components/common/Spinner/Spinner';
import { Helmet } from 'react-helmet-async';

const EditSkisPage = () => {
    const { updateSki, loading: updating, error: updateError } = useSkis();
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { ski, loading, error } = useSingleSki(id);

    const handleUpdateSki = async (formData) => {
        await updateSki(id, formData);
        navigate('/skis');
    };

    if (loading || updating) return <Spinner />;
    if (error || updateError) return <div className='m-2'>Error: {error?.message || updateError?.message}</div>;
    if (!ski) return <div className='m-2'>{t('ski_not_found')}</div>;

    return (
        <div className="py-4 px-2">
            <Helmet>
                <title>Ski-Lab: Edit skis</title>
                <meta name="description" content="Edit skis" />

            </Helmet>
            <SkiForm initialData={ski} onSubmit={handleUpdateSki} isEdit={true} />
        </div>
    );
};

export default EditSkisPage;

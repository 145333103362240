import React from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { useProfileActions } from '../../hooks/useProfileActions';
import Spinner from '../../components/common/Spinner';
import ProfileImage from '../../components/profileImage/ProfileImage';
import GetPro from '../../components/getPro/GetPro';
import { RiVerifiedBadgeFill } from "react-icons/ri";

const AccountPage = () => {
  const { user, userData, checkingStatus } = useAuth();
  const { isChangingImg, errorMessage, updateProfileImage } = useProfileActions(user);

  const { t } = useTranslation();

  if (checkingStatus) {
    return <Spinner />;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Norwegian date format: day.month.year
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    return date.toLocaleDateString('nb-NO', options);
  };


  return (
    <HelmetProvider>
      <Helmet>
        <title>Ski-Lab: Account</title>
      </Helmet>
      <div className="grid grid-cols-1 md:grid-cols-2  mx-auto mt-4 md:mt-32 px-4 animate-fade-down animate-duration-300 md:space-x-10 ">
        <ProfileImage
          isChangingImg={isChangingImg}
          photoURL={user?.photoURL}
          handleImageChange={updateProfileImage}
        />
        <div className="flex flex-col text-center">
          <p>{t('hello')}</p>
          <h1 className="font-bold text-3xl mb-2">{user?.displayName || 'there'}</h1>
          {errorMessage && <div className="error-message text-red-500">{errorMessage}</div>}

          {userData && (
            <div className="text-sm font-semibold mb-4">
              {userData.isPro ? (
                <p className='flex text-btn justify-center'>
                  {t('proUser')} <RiVerifiedBadgeFill />
                </p>
              ) : (
                <p className='flex justify-center items-center'>
                  {t('freeUser')}
                </p>
              )}
            </div>
          )}

          <div className='flex flex-col space-y-4 my-4 w-2/3 m-auto md:w-full'>
            <div className='flex space-x-1 justify-center text-btn'>
              <GetPro />
            </div>

            <Link className='flex self-center py-3 px-5 bg-btn w-full rounded text-btntxt items-center justify-center hover:opacity-90' to={'/settings'}>
              {t('settings')}
            </Link>
          </div>
          <div className=''>
            <p className="text-sm">{t('joined')}: {user?.metadata.creationTime ? formatDate(user.metadata.creationTime) : ''}</p>
          </div>
        </div>

      </div>
    </HelmetProvider>
  );
};

export default AccountPage;

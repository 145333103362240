import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { addContactFormSubmission } from '../../firebase/firestoreFunctions';
import { useAuth } from '../../context/AuthContext';


const ContactPage = () => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('')
  const { user } = useAuth();


  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action
    setStatus('')

    try {
      // Use the function from firestoreFunctions.js
      const docId = await addContactFormSubmission(subject, message, user.uid);
      console.log("Document written with ID: ", docId);
      setStatus('Success!');
      setSubject('');
      setMessage('');
      // Handle additional UI updates or resets here
    } catch (error) {
      console.error("Error adding document: ", error);
      setStatus('Error sending message:', error.message);
      // Handle error in UI, such as showing a message to the user
    }
  };

  return (
    <>
      <Helmet>
        <title>Ski-Lab: Contact</title>
        <meta name="description" content="Contact and get in touch with the website creator" />

      </Helmet>
      <div className='p-4 animate-fade-down animate-duration-300'>

        {/* Update the form to call handleSubmit when submitted */}
        <form className='flex flex-col my-5' onSubmit={handleSubmit}>
          <label htmlFor="subject" className='font-semibold mb-1'>{t('subject')}</label>
          <input
            type="text"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder={t('subject')}
            className='text-inputtxt rounded border p-2 mb-4'
            maxLength={100}
            required // Make this field required
          />
          <label htmlFor="message" className='font-semibold mb-1'>{t('message')}</label>
          <textarea
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="5"
            placeholder={t('message')}
            className='text-inputtxt rounded border p-2 mb-4'
            maxLength={500}
            required // Make this field required
          ></textarea>
          <input type="submit" value={t('submit')} className='bg-btn text-btntxt hover:opacity-90 cursor-pointer py-3 px-5 rounded w-fit' />
        </form>

        {status && (<div>{status}</div>)}

      </div>
    </>
  )
}

export default ContactPage
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './i18n';
import { AuthProvider } from './context/AuthContext';
import { UserPreferencesProvider } from './context/UserPreferencesContext';

import Layout from './routes/Layout';
import { TournamentProvider } from './context/TournamentContext';

function App() {
  return (
    <AuthProvider>
      <UserPreferencesProvider>
        <TournamentProvider>
          <HelmetProvider>
            <Router>
              <Layout /> {/* Move the conditional logic to this Layout component */}
            </Router>
          </HelmetProvider>
        </TournamentProvider>
      </UserPreferencesProvider>
    </AuthProvider>
  );
}

export default App;

// EditResultPage.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateTournamentResult } from '../../../firebase/firestoreFunctions';
import { useAuth } from '../../../context/AuthContext';
import SaveTestInput from '../../TestingPage/TestResultPage/SaveTestInput/SaveTestInput';
import { useTranslation } from 'react-i18next';
import BackBtn from '../../../components/common/BackBtn/BackBtn';
import { formatDateForInputWithTime } from '../../../helpers/helpers';
import { Timestamp } from 'firebase/firestore';

const EditResultPage = () => {
  const { t } = useTranslation();
  const { resultId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // 1) Initialize state with nested snowCondition
  const [resultData, setResultData] = useState(() => {
    const initialData = location.state?.resultData || {};

    return {
      location: initialData.location || '',
      style: initialData.style || '',
      temperature: initialData.temperature || '',
      snowTemperature: initialData.snowTemperature || '',
      humidity: initialData.humidity || '',
      comment: initialData.comment || '',

      // The new nested structure:
      snowCondition: {
        source: initialData.snowCondition?.source || '',
        grainType: initialData.snowCondition?.grainType || '',
      },

      timestamp: (() => {
        // Convert the timestamp to a JS Date for the datetime-local input
        if (initialData.timestamp instanceof Date) {
          return initialData.timestamp;
        } else if (initialData.timestamp && initialData.timestamp.toDate) {
          return initialData.timestamp.toDate();
        } else if (initialData.timestamp && initialData.timestamp.seconds) {
          return new Date(initialData.timestamp.seconds * 1000);
        } else if (initialData.timestamp) {
          const date = new Date(initialData.timestamp);
          return isNaN(date.getTime()) ? new Date() : date;
        } else {
          return new Date(); // fallback
        }
      })(),

      // Keep the existing rankings array
      rankings: initialData.rankings
        ? initialData.rankings.map((ranking) => ({ ...ranking }))
        : [],
    };
  });

  // 2) Handle input changes for both top-level and nested snowCondition fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // For radio or select fields within snowCondition:
    if (name === 'source' || name === 'grainType') {
      setResultData((prev) => ({
        ...prev,
        snowCondition: {
          ...prev.snowCondition,
          [name]: value,
        },
      }));
      return;
    }

    if (name.startsWith('score-')) {
      // Updating the "score" of a specific ranking
      const index = parseInt(name.split('-')[1]);
      setResultData((prevData) => {
        const updatedRankings = [...prevData.rankings];
        const updatedRanking = { ...updatedRankings[index] };
        updatedRanking.score = Number(value);
        updatedRankings[index] = updatedRanking;
        return { ...prevData, rankings: updatedRankings };
      });
    } else if (name === 'date') {
      // Convert the datetime-local string to a Date
      const newDate = new Date(value);
      if (!isNaN(newDate.getTime())) {
        setResultData((prevData) => ({
          ...prevData,
          timestamp: newDate,
        }));
      } else {
        console.error('Invalid date provided:', value);
      }
    } else {
      // Normal top-level fields
      setResultData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // 3) Submit changes to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Convert resultData.timestamp to Firestore Timestamp
      let timestampToUse;
      if (resultData.timestamp instanceof Date) {
        timestampToUse = resultData.timestamp;
      } else if (typeof resultData.timestamp === 'string') {
        timestampToUse = new Date(resultData.timestamp);
      } else if (resultData.timestamp && resultData.timestamp.toDate) {
        timestampToUse = resultData.timestamp.toDate();
      } else {
        throw new Error('Invalid timestamp');
      }

      if (isNaN(timestampToUse.getTime())) {
        throw new Error('Invalid timestamp');
      }

      // Convert dateAdded inside each ranking to a Firestore Timestamp
      const rankingsWithTimestamps = resultData.rankings.map((r) => {
        let dateAdded;
        if (r.dateAdded instanceof Date) {
          dateAdded = Timestamp.fromDate(r.dateAdded);
        } else if (r.dateAdded && r.dateAdded.seconds) {
          dateAdded = new Timestamp(r.dateAdded.seconds, r.dateAdded.nanoseconds);
        } else if (typeof r.dateAdded === 'string') {
          dateAdded = Timestamp.fromDate(new Date(r.dateAdded));
        } else {
          dateAdded = Timestamp.now();
        }
        return { ...r, dateAdded };
      });

      // 4) Update Firestore doc, preserving the nested structure
      await updateTournamentResult(user.uid, resultId, {
        ...resultData,
        timestamp: Timestamp.fromDate(timestampToUse),
        rankings: rankingsWithTimestamps,
      });

      navigate('/results');
    } catch (error) {
      console.error('Error updating result:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // We’ll create some radio inputs for source, and a select for grainType:
  const styleOptions = [
    { label: t('classic'), value: 'classic' },
    { label: t('skate'), value: 'skate' },
  ];

  // Radio options for "snowCondition.source"
  const snowSourceOptions = [
    { label: t('natural'), value: 'natural' },
    { label: t('artificial'), value: 'artificial' },
    { label: t('mix'), value: 'mix' },
  ];

  // Select options for "snowCondition.grainType"
  const grainTypeOptions = [
    { label: t('fresh'), value: 'fresh' },
    { label: t('fine_grained'), value: 'fine_grained' },
    { label: t('coarse_grained'), value: 'coarse_grained' },
    { label: t('wet'), value: 'wet' },
    { label: t('icy_conditions'), value: 'icy_conditions' },
    { label: t('sugary_snow'), value: 'sugary_snow' },
  ];

  return (
    <>
      <Helmet>
        <title>SkiLab: Edit Result</title>
        <meta name="description" content="Edit result" />
      </Helmet>

      <div className="py-4 px-2">
        <form onSubmit={handleSubmit}>
          {/* Rankings/score section */}
          {resultData.rankings.map((ranking, index) => (
            <div key={index} className="flex flex-col mb-2">
              <label className="font-semibold">
                {`${ranking.serialNumber || t('deleted')} - ${ranking.grind || ''}`}
              </label>
              <SaveTestInput
                type="number"
                name={`score-${index}`}
                onChange={handleInputChange}
                value={ranking.score}
              />
            </div>
          ))}

          <h3 className="mt-5 mb-2 text-2xl font-semibold text-dominant ">
            {t('test_details')}
          </h3>

          <div className="space-y-2">
            <SaveTestInput
              type="text"
              name="location"
              placeholder={t('location')}
              onChange={handleInputChange}
              value={resultData.location}
              required={true}
            />

            <SaveTestInput
              type="select"
              name="style"
              placeholder={t('style')}
              onChange={handleInputChange}
              value={resultData.style}
              required
              options={styleOptions}
            />

            <SaveTestInput
              type="number"
              name="temperature"
              placeholder={t('temperature')}
              onChange={handleInputChange}
              value={resultData.temperature}
            />

            {/** 5) Radio inputs for snowCondition.source */}
            <SaveTestInput
              type="radio"
              name="source"
              placeholder={t('snow_source')}
              value={resultData.snowCondition.source}
              onChange={handleInputChange}
              required
              options={snowSourceOptions}
            />

            {/** 6) Select for snowCondition.grainType */}
            <SaveTestInput
              type="select"
              name="grainType"
              placeholder={t('snow_type')}
              value={resultData.snowCondition.grainType}
              onChange={handleInputChange}
              options={grainTypeOptions}
              required
            />

            <SaveTestInput
              type="number"
              name="snowTemperature"
              placeholder={t('snow_temperature')}
              onChange={handleInputChange}
              value={resultData.snowTemperature}
            />

            <SaveTestInput
              type="number"
              name="humidity"
              placeholder={t('humidity')}
              onChange={handleInputChange}
              value={resultData.humidity}
            />

            <SaveTestInput
              type="text"
              name="comment"
              placeholder={t('comment')}
              onChange={handleInputChange}
              value={resultData.comment}
            />

            {/** datetime-local for editing the test date */}
            <SaveTestInput
              type="datetime-local"
              name="date"
              placeholder={t('date')}
              onChange={handleInputChange}
              value={formatDateForInputWithTime(resultData.timestamp)}
              required={true}
            />
          </div>

          <div className="flex space-x-4 my-5">
            <button
              type="submit"
              className="bg-btn px-5 py-3 rounded w-fit text-btntxt hover:opacity-90"
              disabled={isSubmitting}
            >
              {isSubmitting ? t('loading') : t('save')}
            </button>
            <BackBtn />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditResultPage;

// components/SkisPage.jsx
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSkis } from '../../hooks/useSkis';
import SkiItem from './SkiItem/SkiItem';
import Spinner from '../../components/common/Spinner';
import { SiRundeck } from "react-icons/si";
import { UserPreferencesContext } from '../../context/UserPreferencesContext';
import { useAuth } from '../../context/AuthContext';
import GetPro from '../../components/getPro/GetPro';
import { RiFilter2Line, RiFilter2Fill, RiAddLine, RiLockLine, RiCloseLine } from "react-icons/ri";
import { TournamentContext } from '../../context/TournamentContext';
import SkiTable from './SkiTable/SkiTable';

// 1) Import the new MUI Drawer filter component
import SkiFilterDrawer from './SkiFilterDrawer/SkiFilterDrawer';

const SkisPage = () => {
  const { skis, loading, error, updateSkisList, deleteSki, updateSki, totalSkis, lockedSkisCount } = useSkis();
  const { gloveMode } = useContext(UserPreferencesContext);
  const { userData } = useAuth();
  const { selectedSkis, setSelectedSkis, currentRound, resetTournament } = useContext(TournamentContext);

  const [selectedSkisMap, setSelectedSkisMap] = useState({});
  // Keep track of both field and direction
  const [sortField, setSortField] = useState('serialNumber');
  const [sortDirection, setSortDirection] = useState('asc');
  const [expandedSkiId, setExpandedSkiId] = useState(null);
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem('viewMode') || 'card';
  });

  // 2) New filter states
  // styleFilter: 'all' | 'classic' | 'skate'
  const [styleFilter, setStyleFilter] = useState(() => {
    return localStorage.getItem('styleFilter') || 'all';
  });
  // skiTypeFilter: 'all' | 'cold' | 'universal' | 'warm'
  const [skiTypeFilter, setSkiTypeFilter] = useState(() => {
    return localStorage.getItem('skiTypeFilter') || 'all';
  });
  // archivedFilter: 'all' | 'archived' | 'notArchived'
  const [archivedFilter, setArchivedFilter] = useState(() => {
    return localStorage.getItem('archivedFilter') || 'notArchived';
  });

  // Moved sorting logic into its own function
  const applySort = (list, field, direction) => {
    return [...list].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  useEffect(() => {
    localStorage.setItem('styleFilter', styleFilter);
    localStorage.setItem('skiTypeFilter', skiTypeFilter);
    localStorage.setItem('archivedFilter', archivedFilter);
    localStorage.setItem('viewMode', viewMode);

  }, [styleFilter, skiTypeFilter, archivedFilter, viewMode]);

  // 3) MUI drawer open/close
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilterDrawer = () => {
    setIsFilterOpen(prev => !prev);
  };

  const isFilterActive =
    styleFilter !== 'all' ||
    skiTypeFilter !== 'all' ||
    archivedFilter !== 'notArchived';

  // "Reset Filter" button inside the drawer
  const resetFilter = () => {
    setStyleFilter('all');
    setSkiTypeFilter('all');
    setArchivedFilter('notArchived');

    // Clear the corresponding items from localStorage
    localStorage.removeItem('styleFilter');
    localStorage.removeItem('skiTypeFilter');
    localStorage.removeItem('archivedFilter');
  };

  const resetStyle = () => {
    setStyleFilter('all')
  }
  const resetSkiType = () => {
    setSkiTypeFilter('all')
  }
  const resetArchive = () => {
    setArchivedFilter('notArchived')
  }

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Update selected skis for tournament whenever selection changes
    getSelectedSkis(); // you might not need to do anything else here
  }, [selectedSkisMap, skis]);

  const toggleDetails = (skiId) => {
    setExpandedSkiId(expandedSkiId === skiId ? null : skiId);
  };

  const getSelectedSkis = () =>
    Object.entries(selectedSkisMap)
      .filter(([_, isSelected]) => isSelected)
      .map(([id]) => skis.find(ski => ski.id === id))
      .filter(ski => ski); // ensure no undefined

  const handleSortChange = (field) => {
    if (field === sortField) {
      // Toggle direction
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      // Switch to new field, reset to 'asc'
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Archive/unarchive logic
  const handleArchive = async (skiId) => {
    if (window.confirm(t('archive_ski_promt'))) {
      const skiToUpdate = skis.find(ski => ski.id === skiId);
      if (skiToUpdate) {
        await updateSki(skiId, { archived: true });
      }
    }

  };

  const handleUnarchive = async (skiId) => {
    const skiToUpdate = skis.find(ski => ski.id === skiId);
    if (skiToUpdate) {
      await updateSki(skiId, { archived: false });
    }
  };

  // Add, edit, delete skis
  const handleAddSki = () => {
    navigate('/add-skis');
  };

  const handleEdit = (ski) => {
    navigate(`/edit-skis/${ski.id}`);
  };

  const handleDelete = async (skiId) => {
    if (window.confirm(t('delete_ski_promt'))) {
      if (window.confirm(t('delete_ski_promt_2'))) {
        await deleteSki(skiId);
      }
    }
  };

  // Tournament
  const handleCheckboxChange = (skiId) => {
    setSelectedSkisMap(prev => ({ ...prev, [skiId]: !prev[skiId] }));
  };

  const handleStartNewTournament = () => {
    const selectedSkisForTournament = getSelectedSkis();
    if (selectedSkisForTournament.length > 1) {
      if (currentRound.length > 0) {
        const confirmOverwrite = window.confirm(t('confirm_overwrite_test'));
        if (confirmOverwrite) {
          resetTournament();
          setSelectedSkis(selectedSkisForTournament);
          navigate('/testing');
        }
      } else {
        setSelectedSkis(selectedSkisForTournament);
        navigate('/testing');
      }
    } else {
      alert(t('select_at_least_two_skis'));
    }
  };

  // For counting how many skis a user can have
  const skiCount = userData?.skiCount || 0;
  const skiLimit = userData?.isPro ? 48 : 12;
  const hasReachedLimit = skiCount >= skiLimit;
  const hasLockedSkis = lockedSkisCount > 0;

  // Handle loading/error from your custom hook
  if (loading) return <Spinner />;
  if (error) return <div className='m-2'>Error: {error.message}</div>;

  // 5) Filter logic combining style, skiType, and archived
  const filteredSkis = skis.filter(ski => {
    // archived logic
    if (archivedFilter === 'notArchived' && ski.archived) return false;
    if (archivedFilter === 'archived' && !ski.archived) return false;
    // if archivedFilter === 'all', skip

    // style filter
    if (styleFilter !== 'all' && ski.style !== styleFilter) return false;

    // skiType filter
    if (skiTypeFilter !== 'all' && ski.skiType !== skiTypeFilter) return false;

    return true;
  });

  // 6) Sort after filtering (using your chosen `sort` field)
  const sortedAndFilteredSkis = applySort(filteredSkis, sortField, sortDirection);


  // 7) Render
  return (
    <>
      <Helmet>
        <title>Ski-Lab: Skis</title>
        <meta name="description" content="List of your added skis" />
      </Helmet>

      <div className="skis-page pb-2 px-2">

        {/* Top row: "Selected skis" + "Add Ski" button, etc. */}

        <div className={`flex items-end justify-between p-2`}>

          {/* Left block: "Selected skis" + "Start new test" */}
          <div className='flex flex-col items-center justify-end'>
            <h3 className="text-sm font-semibold mb-1">
              {getSelectedSkis().length} {t('skipairs_selected')}
            </h3>
            <button
              onClick={handleStartNewTournament}
              className={`flex h-fit items-center justify-center bg-btn text-btntxt shadow py-3 px-5 text-center rounded w-full max-w-xs
                  ${getSelectedSkis().length < 2 ? "opacity-50 cursor-default" : "hover:opacity-90"}`}
              disabled={getSelectedSkis().length < 2}
              title={getSelectedSkis().length < 2 ? t('select_at_least_two_skis') : ''}
            >
              {t('new_test')}
              <SiRundeck className="ml-2" />
            </button>
          </div>

          {/* Right block: "Ski count" + "Add Ski" */}
          <div className='flex space-x-2 items-end'>

            <div className='flex flex-col items-center w-fit'>
              <label
                className={`text-sm font-semibold mb-1
                  ${hasReachedLimit && 'text-delete'}`}
              >
                {skiCount}/{skiLimit}
              </label>
              <button
                onClick={handleAddSki}
                disabled={hasReachedLimit}
                className={`
                bg-container flex items-center p-3 shadow rounded
                hover:bg-btn hover:text-btntxt
                ${hasReachedLimit ? 'opacity-50 text-delete cursor-not-allowed disabled:pointer-events-none' : ''}
                  `}
                title={hasReachedLimit ? t('max_skis_reached') : ''}
              >
                {hasReachedLimit ? <RiLockLine /> : <RiAddLine />}


              </button>
            </div>




            {/* Sorting & Filter & View toggles */}
            <div className="flex flex-col items-center w-fit">

              {/* Existing separate sorting component */}
              <div className='flex space-x-4 md:space-x-2'>

                <div className='flex flex-col items-center w-fit'>
                  {/* New filter button to open Drawer */}
                  <label className="text-sm font-semibold mb-1">{t('filter')}</label>

                  <button
                    onClick={toggleFilterDrawer}
                    className={`
                      bg-container flex items-center p-3 shadow rounded
                      hover:bg-btn hover:text-btntxt
                      ${isFilterActive && 'text-btn'}
                    `}>
                    {isFilterActive ? <RiFilter2Fill /> : <RiFilter2Line />}

                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        {(hasLockedSkis || (hasReachedLimit && !userData.isPro)) && (
          <div className='flex mx-2 my-4 space-x-4'>


            {hasLockedSkis &&
              <div className="flex flex-1 flex-col bg-container shadow py-4 rounded  items-center justify-end text-center space-y-2">
                <div className="flex space-x-1">
                  <RiLockLine />
                  <h3 className='text-sm'>{lockedSkisCount} {t('locked_ski(s)')}</h3>
                </div>
                <button
                  onClick={() => navigate('/manage-locked-skis')}
                  className="flex h-fit w-fit justify-center
                    bg-delete text-btntxt shadow py-3 px-5 rounded"
                >
                  {t('view_skis')}
                </button>
              </div>
            }

            {hasReachedLimit && !userData.isPro &&
              <div className='flex flex-1 flex-col bg-container shadow py-4 rounded items-center justify-end text-center space-y-2'>
                <h3 className='text-sm'>{t('upgrade_promt')}</h3>
                <GetPro />
              </div>
            }
          </div>
        )}



        {/* The new MUI drawer with style, skiType, archived filters */}
        <SkiFilterDrawer
          open={isFilterOpen}
          onClose={toggleFilterDrawer}
          styleFilter={styleFilter}
          setStyleFilter={setStyleFilter}
          skiTypeFilter={skiTypeFilter}
          setSkiTypeFilter={setSkiTypeFilter}
          archivedFilter={archivedFilter}
          setArchivedFilter={setArchivedFilter}
          resetFilter={resetFilter}

          // The unified sort + direction:
          sortField={sortField}
          setSortField={setSortField}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}

          viewMode={viewMode}
          setViewMode={setViewMode}
        />

        {/* Show filters if active */}
        {(styleFilter !== 'all' || skiTypeFilter !== 'all' || archivedFilter !== 'notArchived') && (
          <div className='flex space-x-2 text-sm mx-2 mt-2'>
            {styleFilter !== 'all' && <span className='flex items-center bg-container shadow rounded p-2 cursor-pointer hover:bg-background' onClick={resetStyle}>{t(styleFilter)} <RiCloseLine />
            </span>}
            {skiTypeFilter !== 'all' && <span className='flex items-center bg-container shadow rounded p-2 cursor-pointer hover:bg-background' onClick={resetSkiType}>{t(skiTypeFilter)} <RiCloseLine />
            </span>}
            {archivedFilter !== 'notArchived' && <span className='flex items-center bg-container shadow rounded p-2 cursor-pointer hover:bg-background' onClick={resetArchive}>{t(archivedFilter)} <RiCloseLine />
            </span>}
          </div>
        )}




        {/* If no skis after filtering */}
        {sortedAndFilteredSkis.length === 0 && (
          <div className='my-4 mx-2'>
            <div className='italic'>{t('you_have_no')} {t('skis')}.</div>
          </div>
        )}

        {/* Render either Card view or Table view */}
        {viewMode === 'card' ? (
          <div className={`flex flex-col ${gloveMode ? 'grid grid-cols-2 gap-4' : 'space-y-2'} m-2`}>
            {sortedAndFilteredSkis.map(ski => (
              <SkiItem
                key={ski.id}
                ski={ski}
                handleCheckboxChange={handleCheckboxChange}
                handleEdit={() => handleEdit(ski)}
                handleDelete={handleDelete}
                handleArchive={handleArchive}
                handleUnarchive={handleUnarchive}
                selectedSkis={selectedSkisMap}
                expandedSkiId={expandedSkiId}
                toggleDetails={toggleDetails}
              />
            ))}
          </div>
        ) : (
          <div className="m-2">
            <SkiTable
              skis={sortedAndFilteredSkis}
              sortField={sortField}
              sortDirection={sortDirection}
              onSort={handleSortChange}  // table calls this when user clicks a <th>
              onEdit={handleEdit}
              onDelete={handleDelete}
              onArchive={handleArchive}
              onUnarchive={handleUnarchive}
            />
          </div>
        )}
      </div >
    </>
  );
};

export default SkisPage;

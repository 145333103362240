import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackBtn = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div>
            <button type='button' className='flex items-center px-5 py-3 bg-sbtn text-text rounded hover:bg-hoverSbtn' onClick={() => { navigate(-1) }}>{t('back')}</button>

        </div>
    )
}

export default BackBtn;
